<template>
    <div class="container-bg" @click="closeModal">
        <div class="container" v-if="like==true" >
            <span id="cross" @click="hide"><img src="../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>

            <div v-if="language=='fr'" class="txt-container">
                <center><img src="../../../public/assets/images/heart.png" width="34" /></center>
                <h3 class="title">PRODUIT AJOUTÉ AUX FAVORIS</h3>
                <p class="txt">Vos favoris sont accessibles depuis votre page compte, située dans l’onglet ‘Favoris & Enregistrements’.</p>
            </div>
            <div v-else class="txt-container">
                <center><img src="../../../public/assets/images/heart.png" width="34" /></center>
                <h3 class="title">PRODUCT ADDED TO FAVORITES</h3>
                <p class="txt">Your favorites products are available on your account page, located in 'the Favorites & Savings' toggle.</p>
            </div>
            <div v-if="language=='fr'"  class="actions">
                <button class="btn white" @click="hide">Retour à la collection</button>
                
                <button class="btn" @click="goToLikes()" >Voir vos favoris</button>
            </div>
            <div v-else class="actions">
                <button class="btn white" @click="hide">Back to collection</button>
                
                <button class="btn" @click="goToLikes()" >See your favorites</button>
            </div>
            
        </div>
        <div class="container" v-else>
            <div class="txt-container">
                <!-- <h3 class="title">{{title}}</h3> -->
                <p v-if="title" class="txt">
                    <span v-html="title"></span>
                  </p>
            </div>
            <div class="actions"  v-if="language=='fr'">
                <button class="btn" @click="hide">Fermer</button>
             
                <button v-if="like==true" class="btn" @click="gotoLikes()">Cliquez ici pour voir les favoris</button>
                <button v-if="favorite==true" class="btn" @click="goToFavorites()" >Cliquez ici pour voir les enregistrements</button>
            </div>
            <div class="actions"  v-else>
               
                <button class="btn"  @click="hide">Close</button>
                <button v-if="like==true" class="btn" @click="gotoLikes()">Click here to see the favorites</button>
                <button v-if="favorite==true" class="btn" @click="goToFavorites()" >Click here to see the savings.</button>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    name: "OverlayComponent",
    props:["title", "txt", "favorite", "save", "like","language"],
    methods: {
        goToFavorites() {
            this.$emit('remove')
            this.$router.push({ name: 'likes' , params: { toggle: 'favorites' } })
        },
        gotoLikes(){
            this.$emit('remove')
            this.$router.push({ name: 'likes' })
        },
        hide() {
          
            this.$emit('remove')
        },
        closeModal($event)
        {
            
            if (jQuery($event.target).parents('.container').length) {

            }
            else
            {
              this.$emit('remove')
            }
        },
    }
}
</script>

<style scoped>

.actions .btn {
    margin-left:10px;
    margin-right:10px;
}
.container-bg {
    position: fixed;
    top:0;
    left:0;
    width:100%;
    z-index: 10000;
    height:100%;
    background:rgba(0, 0, 0, 0.8);
}
.container{
    background-color: white;
    width: auto;
    max-width: 500px;
    padding: 1.5rem;
    border-radius: 11px;
    font-size: 1rem;
    box-shadow: 0 1rem 3rem rgba(0,0,0,0.175);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
}

.txt-container{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    text-align: center;
}

.title{
    font-size: 35px;
    margin-bottom: 0.5rem;
    font-weight: 600;
    margin-top: 0.5rem;
}

.txt {
    color: #000;
    font-size:14px;
    padding-bottom: 0;
}

.btn.white
{
    background:#fff;
    color:#000;
}
#cross
{
  position: absolute;
  right:-10px;
   top:-10px;
    cursor: pointer;
    border-radius: 50%;
    padding: 7px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
}
.title,.txt 
{
    width:70%;
    margin-left:auto;
    margin-right: auto;
}
.btn{
    border:0;
    background: #000 ;
    border-radius: 0;
    color: #fff;
    text-align: center;
    width: auto;
    padding:13px 30px;
    
    border:1px solid black;
    display: block;
    font-weight: 600;
    text-decoration: none !important;
    text-transform: none;
    font-size: 13px;
    transition: background-color 0.15s ease-in-out;
}

.btn:hover {
    background: rgba(0, 0, 0, 0.8);
    color:#fff;
}



.bounce-enter-active {
    animation: bounce-in .5s ease-out both;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse ease-in both;
}

.actions{
    padding-bottom:1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}

@media screen and (max-width: 760px){
	.container{
        width: 80%;
    }
    .container .title {
        font-size: 16px !important;
    }
    .actions
    {
        flex-direction: column;
    }
    .btn 
    {
        margin-bottom:10px;
    }
    .title,.txt 
{
    width:100%;
}
}

</style>
